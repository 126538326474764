.main {
  padding: 30px 15px;
  font-size: 16px;
  display: block;
  overflow-y: scroll;
}

.footer__pagination {
  margin-bottom: 20px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
}
