.sidenav {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(221, 219, 219);
  overflow-x: hidden;
  padding-top: 80px;

  a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    color: #050505;
    display: block;
  }

  a:hover {
    color: #f1f1f1;
  }
}

@media (min-width: 900px) {
  .sidenav {
    width: 250px;
    position: inherit;
  }
}

@media (min-width: 1024px) {
  .sidenav {
    width: 300px;
    position: inherit;
  }
}
